<template>
  <div>
    <div class="row">
      <div class="col-lg-3 col-md-12">
        <div class="card-placeholder">
          <div class="card-body mt-2 ml-2">
               <filter-regional v-model:value="regionals" />
          </div>
           <div class="card-body mt-2 ml-2">
               <filter-area v-model:value="areas" v-model:province="province" />
          </div>
           <div class="card-body mt-2 ml-2">
               <filter-distributor v-model:value="distributors"></filter-distributor>
          </div>
          <div class="card-body mt-2 ml-2">
              <a-date-picker v-model:value="value2" :format="dateFormatList" placeholder=" Tanggal" />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12">
        <div class="card-placeholder">
          <div class="card-body ml-2 mt-2">
               <filter-province v-model:value="province" v-model:regional="regionals" />
          </div>
           <div class="card-body ml-2 mt-2">
               <filter-kabupaten-kota v-model:value="kabupaten" v-model:area="areas" />
          </div>
           <div class="card-body ml-2 mt-2">
               <a-select label-in-value v-model:value="value" style="width: 300px" @change="handleChange" placeholder="Pilih Gudang">
            <a-select-option value=""></a-select-option>
             </a-select>
          </div>
          <div class="card-body ml-2 mt-2">
               <a-date-picker v-model:value="value2" :format="dateFormatList" placeholder=" Tanggal" />
               <a-button class="m-2" type="primary" title="cari" @click="search">
                <i class="fa fa-search" aria-hidden="true"></i>
              </a-button>
             <a-button type="primary" title="Tambah Min Max" class="ml-2 mr-2" style="background-color: #606661; border: none; color: white;"
              @click="
                ()=> {
                  formState = {}
                  formState.status_approval = 0
                  formState.type = 1
                  setModal1Visible(true)
                  formState.title_state = 'Tambah Min Max'
                  formState.state = 'new'
                  formState.readOnly = false
                }
              "
                  >

                <i class="fa fa-plus" aria-hidden="true"></i>
              </a-button>
              <a-button
                type="primary"
                stylea="width: 200px; background-color: #3acf41; border: none;"
                title="Download Xls"
                @click="fetchXlsx"
              >
                <i class="fa fa-file-excel-o" aria-hidden="true"></i>
              </a-button>
          </div>
        </div>
      </div>
  </div>
  </div>
                <a-modal
                    v-model:visible="modal1Visible"
                    title="Tambah Min Max"
                    width="500px"
                   @ok="setModal1Visible(false)"
                  >
                  <template #footer>
                    <a-button key="submit" type="primary" :loading="loading" @click="submit">Ya</a-button>
                  </template>
                  <a-form :wrapper-col="{ span: 14 }" class="myform">
                      <a-row class="form-row">
                        <a-col :sm="24">
                          <a-form-item
                            label="Min"
                            label-align="left"
                            :label-col="{ sm: { span: 6 } }"
                            :wrapper-col="{ sm: { span: 24 - 6 } }"
                          >
                            <a-input
                              placeholder="%"
                              width="100%"
                              v-model:value="formState.keterangan"
                              :disabled="formState.readOnly"
                            />
                          </a-form-item>
                        </a-col>
                      </a-row>

                      <a-row class="form-row">
                        <a-col :sm="24">
                          <a-form-item
                            label="Max"
                            label-align="left"
                            :label-col="{ sm: { span: 6 } }"
                            :wrapper-col="{ sm: { span: 24 - 6 } }"
                          >
                            <a-input
                              placeholder="%"
                              width="100%"
                              v-model:value="formState.keterangan"
                              :disabled="formState.readOnly"
                            />
                          </a-form-item>
                        </a-col>
                      </a-row>
                  </a-form>
             </a-modal>
</template>

<script>

import VbChartsChartistjs3 from '@/@vb/widgets/ChartsChartistjs/3'
import { ref, watch, onMounted, reactive, provide, toRefs } from 'vue'
import apiClient from '@/services/axios'
// import MModal from './modal'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterProvince from '@/components/filter/FilterProvince'
import FilterArea from '@/components/filter/FilterArea'
import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
import FilterDistributor from '@/components/filter/FilterDistributor'

export default {
  components: {
    // MModal,
    FilterRegional,
    FilterProvince,
    FilterArea,
    FilterKabupatenKota,
    FilterDistributor,
  },
  setup(){

    const modal1Visible = ref(false);
    const setModal1Visible = visible => {
      modal1Visible.value = visible;
    };

    const handleOk = () => {
      loading.value = true;
      setTimeout(() => {
        loading.value = false;
        visible.value = false;
      }, 2000);
    };

    const handleCancel = () => {
      visible.value = false;
    };

    return{
      modal1Visible,
      setModal1Visible,
      handleOk,
      handleCancel,
    }
  },
}
</script>
